import yuu from './yuu.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1>阿悠有棍仲要係BIG JJ</h1>
        <img src={yuu} className="Yuu" alt="yuu" />
      </header>
    </div>
  );
}

export default App;
